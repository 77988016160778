import { Component, HostListener, OnDestroy, ViewChild } from '@angular/core';
import { IForm } from '../../../core/models/IForm';
import { CreateOrderMeta } from '../meta';
import { NgForm } from '@angular/forms';
import { LookupService } from '../../../core/service/lookup.service';
import { OrderLookup } from '../../../contants';
import { ILookup, ILookupItem } from '../../../core/models/ILookup';
import { FormBuilderComponent } from '../../shared-component/form-builder/form-builder.component';
import { OrderService } from '../../../core/service/order.service';
import { AuthenticationService } from '../../../core/service/authentication.service';
import { UserStoreMapping } from '../../../core/models/IUserProfile';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { ProgressLoaderComponent } from '../../../shared/progress-loader/progress-loader.component';
import { NotifierModule, NotifierService } from 'angular-notifier';
import { OrderStatus } from '../../../core/enum/order-status.enum';

@Component({
  selector: 'app-create',
  standalone: true,
  imports: [NotifierModule, ProgressLoaderComponent, FormBuilderComponent, RouterLink, RouterLinkActive],
  templateUrl: './create.component.html',
  styleUrl: './create.component.css'
})
export class CreateComponent {
  createOrder: IForm[] = CreateOrderMeta;

  constructor(private notifier: NotifierService, private lookupService: LookupService, private orderService: OrderService, private authService: AuthenticationService,
    private router: Router
  ) {
    this.resetOrderMeta();
  }
  isLoading: boolean = false;
  resetOrderMeta() {
    this.createOrder[0].SectionDetail[0].defaultValue = ""
    this.createOrder[1].SectionDetail[0].defaultValue = OrderStatus.DRAFT;
    this.createOrder[1].SectionDetail[1].defaultValue = "";
    this.createOrder[2].SectionDetail[0].defaultValue = "";
    this.createOrder[2].SectionDetail[1].defaultValue = "";
    this.createOrder[3].SectionDetail[0].defaultValue = "";
    this.createOrder[3].SectionDetail[1].defaultValue = "";
    this.createOrder[4].SectionDetail[0].defaultValue = "";
    this.createOrder[5].SectionDetail[0].Data = [];
  }
  rowData: any[] = [];
  selectedData(response: any) {

    this.rowData = response;
  }

  deleteRows() {

    this.createOrder[5].SectionDetail[0].Data = this.createOrder[5].SectionDetail[0].Data?.filter(x =>
      !this.rowData.some(item => item.name === x.name)
    );
  }

  userStoreMapping!: UserStoreMapping;
  ngOnInit() {
    console.log(this.createOrder);
    this.getOrderStatusList();
    this.authService.store$.subscribe(m => {
      this.userStoreMapping = m;
    });
  }

  getOrderStatusList() {
    this.lookupService.getLookup(OrderLookup).subscribe((status: ILookupItem[]) => {
      this.createOrder[1].SectionDetail[0].Data = status

    });
  }

  @HostListener('document:paste', ['$event'])
  async onPaste(event: ClipboardEvent) {

    const clipboardData = event.clipboardData;
    if (clipboardData) {
      const pastedData = clipboardData.getData('text/plain');
      this.parseData(pastedData);
    }
  }
  numberValues = [""];
  parseData(pastedData: string) {
    var headerData = this.createOrder[5].SectionDetail[0].ColumnDefinition;
    var headers = headerData?.map((col) => col.field);
    const rows = pastedData.split('\n').map(row => row.trim()).filter(row => row);
    var pastedJson = rows.map((row) => {
      const columns = row.split('\t').map(column => column.trim());

      return headers?.reduce((acc, header, index) => {
        let isNumber = headerData![index].type === "number";
        acc[header] = columns[index] || (isNumber ? "0" : '');

        return acc;
      }, {});
    });
    console.log(pastedJson)
    this.createOrder[5].SectionDetail[0].Data = pastedJson;
  }



  addNamesToInventory(inventory: any) {
    return inventory.map((item: any) => {
      let name = '';

      if (item.thread?.toLowerCase() === 'ft' || item.thread?.toLowerCase() === 'ht') {
        name = `${item.size}-${item.grade}-${item.finish}-${item.type}-${item.thread}`;
      } else {
        name = `${item.size}-${item.grade}-${item.finish}-${item.type}`;
      }
      name = name.replace("HEX BOLT", "BOLT");

      return {
        ...item,
        name: this.modifyString(name)
      };
    });
  }

  modifyString(input: string) {
    if (input.startsWith('-')) {
      input = input.slice(1);
    }
    if (input.endsWith('-')) {
      input = input.slice(0, -1);
    }
    input = input.replace(/--/g, '-');

    return input.trim();
  }

  saveOrder(requestBody: any) {
    this.isLoading = true;
    requestBody.storeId = this.userStoreMapping.storeId;
    requestBody.orderDetails = this.addNamesToInventory(requestBody.orderDetails)
    this.orderService.createOrder(requestBody).subscribe((resp) => {
      this.isLoading = false;
      this.router.navigate([`/order`]);

    });
  }
}
