import { Component, HostListener } from '@angular/core';
import { IForm } from '../../../core/models/IForm';
import { UpdateConfirmationOrderMeta, UpdateDraftOrderMeta, UpdateOrderMeta } from '../meta';
import { FormBuilderComponent } from '../../shared-component/form-builder/form-builder.component';
import { ActivatedRoute, Router, RouterLink, RouterLinkActive } from '@angular/router';
import { OrderService } from '../../../core/service/order.service';
import { IOrderDetail, IOrderResponse } from '../../../core/models/IOrder';
import { CommonModule } from '@angular/common';
import { LookupService } from '../../../core/service/lookup.service';
import { ILookupItem } from '../../../core/models/ILookup';
import { ConfirmOrder, OrderLookup } from '../../../contants';
import moment from 'moment';
import { AuthenticationService } from '../../../core/service/authentication.service';
import { UserStoreMapping } from '../../../core/models/IUserProfile';
import { AgGridAngular } from 'ag-grid-angular';
import { OrderStatus } from '../../../core/enum/order-status.enum';
import { NotifierModule, NotifierService } from 'angular-notifier';
import { ProgressLoaderComponent } from '../../../shared/progress-loader/progress-loader.component';
import { ActionDeleteButtonRendererComponentComponent } from '../../../ag-grid/action-delete-button-renderer-component/action-delete-button-renderer-component';
import { number } from 'echarts';
import { StockService } from '../../../core/service/stock.service';


@Component({
  selector: 'app-edit',
  standalone: true,
  imports: [NotifierModule, ProgressLoaderComponent, AgGridAngular, FormBuilderComponent, CommonModule, RouterLink, RouterLinkActive],
  templateUrl: './edit.component.html',
  styleUrl: './edit.component.css'
})
export class EditComponent {
  isLoading: boolean = false;
  isloaded: boolean = false;
  isRecievingConfirmModalOpen: boolean = false;
  userStoreMapping!: UserStoreMapping;
  isRecievingModalOpen: boolean = false;
  recievingRowData: any[] = [];
  recievingColumnDefs: any[] = [];
  stockType:string = "Import";

  ColDef() {
    this.recievingColumnDefs = [
      { field: "Date",headerName: "Date(eg. 01-Jan-25)", editable: true }, 
      { field: "Rack", editable: true },
      { field: "Level", editable: true },
      { field: "Grade", headerName: this.userStoreMapping?.store?.lookup?.lookupItems?.filter(x => x.extra === 'attr1')[0]?.value ?? "Grade", },
      { field: "Type", headerName: this.userStoreMapping?.store?.lookup?.lookupItems?.filter(x => x.extra === 'attr2')[0]?.value ?? "Type" },
      { field: "Size", headerName: this.userStoreMapping?.store?.lookup?.lookupItems?.filter(x => x.extra === 'attr3')[0]?.value ?? "Size" },
      { field: "Finish", headerName: this.userStoreMapping?.store?.lookup?.lookupItems?.filter(x => x.extra === 'attr4')[0]?.value ?? "Finish" },
      { field: "Thread", headerName: this.userStoreMapping?.store?.lookup?.lookupItems?.filter(x => x.extra === 'attr5')[0]?.value ?? "Thread" },
      { field: "CTN", editable: true },
      { field: "CTNQty", editable: true },
      { field: "Lose", headerName: 'Quantity Loose', editable: true },
      {
        headerName: 'Actions',
        cellRenderer: ActionDeleteButtonRendererComponentComponent,
        width: 150,
        cellRendererParams: {
          onDelete: this.delete.bind(this)
        }
      },
    ]
  }

  columnDefs =
    [
      { field: "date", headerName: "Date", editable: true },
      
      { field: "grade", headerName: "Grade" },
      { field: "size", headerName: "Size" },
      { field: "type", headerName: "Type" },
      { field: "name", headerName: "Name" },
      { field: "thread", headerName: "Thread" },
      { field: "finish", headerName: "Finish" },
      { field: "ctn", headerName: "CTN", editable: true },
      { field: "quantity", headerName: "CTN Quantity", editable: true },
      { field: "loose", headerName: "Loose Quantity", editable: true },
    ];
  rowData: any[] = [];
  orderStatus: number = 0;
  defaultColDef = {
    sortable: true, filter: true
  };
  gridApi: any;
  remainGridApi: any
  orderId: number = 0;
  isShowSaveButton: boolean = false;
  createOrder: IForm[] = [];
  gridOptions = {
    columnDefs: this.columnDefs,
    rowData: this.rowData,
    sortable: true,
    filter: true,
    editable: true,

    animateRows: true,
    onGridReady: (params: any) => {
      this.gridApi = params.api;
    }
  }



  remainGridOptions = {
    columnDefs: this.recievingColumnDefs,
    rowData: this.recievingRowData,
    sortable: true,
    filter: true,
    editable: true,

    animateRows: true,
    onGridReady: (params: any) => {

      this.remainGridApi = params.api;
    }
  }

  isModalOpen = false;

  constructor(private stockService: StockService,private notifier: NotifierService, private lookupService: LookupService, private orderService: OrderService, private route: ActivatedRoute,
    private router: Router,
    private authService: AuthenticationService
  ) {

  }
  ngOnInit() {

    this.route.params.subscribe(params => {

      this.getOrder(params['id']);

    });

    this.authService.store$.subscribe(m => {
      this.userStoreMapping = m;
      this.ColDef();
    });

  }
  getOrderDetail(orderId: number) {
    this.orderService.getOrderDetail(orderId).subscribe((orderDetail) => {
      if (!this.isOrderDraft() && !this.isOrderConfirmed())
        this.createOrder[5].SectionDetail[0].childern[1].SectionDetail[0].Data = this.transformData(orderDetail);

    })
  }

  transformData(data: any[]): any[] {
    return data.map(item => {
      item.productName = item?.product?.name;
      item.thread = item.product?.threadLookup?.value;
      item.type = item.product?.typeLookup?.value;
      item.size = item.product?.sizeLookup?.value;
      item.grade = item.product?.gradeLookup?.value;
      item.finish = item.product?.finishLookup?.value;
      return {
        data: item,  // The main order data
        children: item.shippingDetail.map((shipping: any) => ({
          data: shipping // The child shipping detail data
        }))
      };
    });
  }

  openModal() {
    this.isModalOpen = true;
  }

  openRecievingModal() {
    this.isRecievingModalOpen = true;
  }

  closeRecievingModal() {
    this.isRecievingModalOpen = false;
  }
  closeConfirmModal() {
    this.isRecievingConfirmModalOpen = false;
  }

  openConfirmModal() {
    this.isRecievingConfirmModalOpen = true;
  }

  refreshView() {
    this.router.navigateByUrl(`/`, { skipLocationChange: true }).then(() =>
      this.router.navigate([`/order/edit/${this.orderId}`]));
  }

  closeModal() {
    this.refreshView();
    this.isModalOpen = false;
  }

  getOrderStatusList() {
    this.lookupService.getLookup(OrderLookup).subscribe((status: ILookupItem[]) => {
      this.createOrder[0].SectionDetail[1].Data = status

    });
  }
  isOrderShipped() {

    return this.orderStatus == OrderStatus.SHIPPED;
  }
  isOrderDraft() {
    return this.orderStatus == OrderStatus.DRAFT;
  }

  isOrderConfirmed() {
    return this.orderStatus == OrderStatus.CONFIRMED;
  }

  getOrder(id: string) {
    this.orderService.getOrderById(id).subscribe((order: IOrderResponse) => {

      this.orderId = order.orderId;
      this.orderStatus = order.orderStatusId;
      if (order.orderStatusId == OrderStatus.DRAFT) {
        this.isShowSaveButton = true;
        this.createOrder = UpdateDraftOrderMeta;
      } else if (order.orderStatusId == OrderStatus.CONFIRMED) {
        this.createOrder = UpdateConfirmationOrderMeta
      }
      else {
        this.isShowSaveButton = false;
        this.createOrder = UpdateOrderMeta;
      }
      this.getOrderStatusList();
      this.getOrderDetail(this.orderId)
      this.renderInitObject(order);
      this.isloaded = true;
    })
  }

  renderInitObject(order: IOrderResponse) {
    this.createOrder[1].SectionDetail[0].defaultValue = order.orderRef;
    this.createOrder[0].SectionDetail[0].defaultValue = order.orderId;
    this.createOrder[0].SectionDetail[1].defaultValue = order.orderStatusId;
    this.createOrder[1].SectionDetail[1].defaultValue = order.vendorName;
    this.createOrder[2].SectionDetail[0].defaultValue = moment(order.expectedShipmentDate).format("YYYY-MM-DD");
    this.createOrder[2].SectionDetail[1].defaultValue = order.vendorAddress;
    this.createOrder[3].SectionDetail[0].defaultValue = moment(order.expectedDeliveryDate).format("YYYY-MM-DD");
    this.createOrder[3].SectionDetail[1].defaultValue = order.contactNo;
    this.createOrder[4].SectionDetail[0].defaultValue = order.remarks;
    if (this.isOrderDraft() || this.isOrderConfirmed())
      this.createOrder[5].SectionDetail[0].Data = this.getDetailData(order.orderDetails);
    else
      this.createOrder[5].SectionDetail[0].childern[0].SectionDetail[0].Data = this.getDetailData(order.orderDetails);

  }

  getDetailData(detail: IOrderDetail[]) {

    return detail.map((item) => {
      item.loose = `${item.loose}`;
      item.ctn = `${item.ctn}`;
      item.quantity = `${item.quantity}`;
      return item;
    });
  }
  requestContent: any;
  saveOrderConfirmation(requestBody: any) {
    this.requestContent = requestBody;
    console.log(requestBody);
    if (requestBody.orderStatusId == OrderStatus.DRAFT)
      this.saveOrder();
    else
      this.openModal();
  }

  selectedData(response: any) {

    this.rowData = response;
  }

  saveOrder() {
    this.isLoading = true;
    let requestBody = this.requestContent;
    requestBody.storeId = this.userStoreMapping.storeId;

    requestBody.orderDetails = this.addNamesToInventory(requestBody.orderDetails)
    this.orderService.updateOrder(requestBody).subscribe((resp) => {
      this.isLoading = false;
      this.refreshView();
    });
  }

  saveShippingOrder() {
    this.isLoading = true;
    let requestBody: any = [];
    this.gridApi.forEachNode((node: any) => {
      let data = node.data;
      data.orderId = this.orderId;
      requestBody.push(data)
    });

    this.orderService.updateShipingOrder(requestBody).subscribe((res) => {
      this.isLoading = false;
      this.refreshView();
    })
  }

  @HostListener('document:paste', ['$event'])
  async onPaste(event: ClipboardEvent) {
    if (!this.isOrderDraft())
      return;
    const clipboardData = event.clipboardData;
    if (clipboardData) {
      const pastedData = clipboardData.getData('text/plain');
      this.parseData(pastedData);
    }
  }

  parseData(pastedData: string) {

    var headerData = this.createOrder[5].SectionDetail[0].ColumnDefinition;
    var headers = headerData?.map((col) => col.field);
    
    const rows = pastedData.split('\n').map(row => row.trim()).filter(row => row);
    var pastedJson = rows.map((row) => {
      const columns = row.split('\t').map(column => column.trim());

      return headers?.reduce((acc, header, index) => {
        let isNumber = headerData![index].type === "number";
        acc[header] = columns[index] || (isNumber ? "0" : '');

        return acc;
      }, {});
    });

    this.createOrder[5].SectionDetail[0].Data = this.createOrder[5].SectionDetail[0].Data?.concat(pastedJson);
  }


  deleteRows() {

    this.createOrder[5].SectionDetail[0].Data = this.createOrder[5].SectionDetail[0].Data?.filter(x =>
      !this.rowData.some(item => item.name === x.name)
    );
  }

  addNamesToInventory(inventory: any) {

    return inventory.map((item: any) => {
      let name = '';

      if (item.thread?.toLowerCase() === 'ft' || item.thread?.toLowerCase() === 'ht') {
        name = `${item.size}-${item.grade}-${item.finish}-${item.type}-${item.thread}`;
      } else {
        name = `${item.size}-${item.grade}-${item.finish}-${item.type}`;
      }
      name = name.replace("HEX BOLT", "BOLT");

      return {
        ...item,
        name: this.modifyString(name)
      };
    });
  }

  emptyAlert() {
    this.notifier.notify('warning', 'Please select stock first!');
  }

  modifyString(input: string) {
    if (input.startsWith('-')) {
      input = input.slice(1);
    }
    if (input.endsWith('-')) {
      input = input.slice(0, -1);
    }
    input = input.replace(/--/g, '-');

    return input.trim();
  }
  totalRecievingCount = 0;
  remainingRecievingCount = 0;
  shippingId = 0;
  recieveProduct(recieveItem: any) {
    console.log(recieveItem)
   this.shippingId = recieveItem.shippingItem.data.id;
    this.totalRecievingCount = ((recieveItem.shippingItem.data.totalBox ?? 0) * (recieveItem.shippingItem.data.quantityPerBox ?? 0)) + (recieveItem.shippingItem.data.quantityLoss ?? 0);
    this.remainingRecievingCount = ((recieveItem.shippingItem.data.totalBox ?? 0) * (recieveItem.shippingItem.data.quantityPerBox ?? 0)) + (recieveItem.shippingItem.data.quantityLoss ?? 0);
    let orderData = [];
    orderData.push({
      Date: moment().format("DD-MMM-YY"),
      Rack: "",
      Level: "",
      Grade: recieveItem.orderDetail.data.grade,
      Name: recieveItem.orderDetail.data.productName,
      Size: recieveItem.orderDetail.data.size,
      Thread: recieveItem.orderDetail.data.thread,
      Type: recieveItem.orderDetail.data.type,
      Finish: recieveItem.orderDetail.data.finish,
      CTN: "0",
      CTNQty: "0",
      Lose: "0",
    });

    this.recievingRowData = orderData;
    this.openRecievingModal();
  }


  confirmOrder() {
    if (this.remainingRecievingCount < 0) {
      this.notifier.notify('warning', 'Order quantity and recieving not matched.');
      return;
    }



    this.openConfirmModal();

  }

  recieveOrder() {
    this.isLoading = true;
    let data =this.recievingRowData ;
    this.stockService.saveStock(data,this.stockType,"",this.userStoreMapping.storeId,false).subscribe((response)=>{
      this.updateStatus();
    })
  }

  updateStatus(){
   var requestBody = {OrderShippingDetailId:`${this.shippingId}`,StatusId:ConfirmOrder}
    this.stockService.updateRecieveStatus(requestBody).subscribe((resp)=>{
      this.isLoading = false;
      this.refreshView();
    });
  }
  duplicateRow() {
    debugger
    let rows = JSON.parse(JSON.stringify(this.recievingRowData));
    let row = JSON.parse(JSON.stringify(rows[0]));
    row.CTN = "0";
    row.CTNQty = "0";
    row.Lose = "0";
    rows.push(row);
    this.recievingRowData = rows;
  }

  delete(row: any) {
    var response = JSON.parse(JSON.stringify(this.recievingRowData));
    if (response.length == 1) {
      this.notifier.notify('warning', 'Deleting record is not allowed!');
      return;
    }
    response.splice(row.Index, 1);
    this.recievingRowData = response;
    this.calcucate();
  }

  onCellValueChanged(data: any) {
   this.calcucate();
  }

  calcucate(){
    let response = 0;
       this.recievingRowData.forEach((data: any) => {
      response += (Number(data.CTN ?? 0) * Number(data.CTNQty ?? 0)) + Number(data.Lose ?? 0);
    });
    let total = Number(this.totalRecievingCount ?? 0);
    this.remainingRecievingCount = total - response;
  }

}
